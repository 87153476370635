<template>
  <transition name="fade-in-up">
    <router-view :key="user"></router-view>
  </transition>
</template>


<script>
export default {
  computed: {
    user() {
      return this.$route.params.userID
    }
  }
}
</script>
